import marker_amber      from "../static/icons/map-marker-amber.svg";
import marker_blue       from "../static/icons/map-marker-blue.svg";
import marker_dkblue     from "../static/icons/map-marker-dkblue.svg";
import marker_brown      from "../static/icons/map-marker-brown.svg";
import marker_cyan       from "../static/icons/map-marker-cyan.svg";
import marker_deeppurple from "../static/icons/map-marker-deeppurple.svg";
import marker_dkorange   from "../static/icons/map-marker-dkorange.svg";
import marker_green      from "../static/icons/map-marker-green.svg";
import marker_dkgreen    from "../static/icons/map-marker-dkgreen.svg";
import marker_grey       from "../static/icons/map-marker-grey.svg";
import marker_indigo     from "../static/icons/map-marker-indigo.svg";
import marker_lime       from "../static/icons/map-marker-lime.svg";
import marker_ltblue     from "../static/icons/map-marker-ltblue.svg";
import marker_ltgreen    from "../static/icons/map-marker-ltgreen.svg";
import marker_orange     from "../static/icons/map-marker-orange.svg";
import marker_pink       from "../static/icons/map-marker-pink.svg";
import marker_purple     from "../static/icons/map-marker-purple.svg";
import marker_red        from "../static/icons/map-marker-red.svg";
import marker_teal       from "../static/icons/map-marker-teal.svg";
import marker_yellow     from "../static/icons/map-marker-yellow.svg";
import shadow            from "../static/icons/shadow.png";
import beer              from "../static/icons/beer.svg";
import bike              from "../static/icons/bike.svg";
import coffee            from "../static/icons/coffee.svg";
import home              from "../static/icons/home.svg";
import shopping          from "../static/icons/shopping-bag.svg";
import utensils          from "../static/icons/utensils.svg";
import anchor            from "../static/icons/anchor.svg";
import applealt          from "../static/icons/apple-alt.svg";
import campground        from "../static/icons/campground.svg";
import hotel             from "../static/icons/hotel.svg";
import parking           from "../static/icons/parking.svg";
import restroom          from "../static/icons/restroom.svg";
import school            from "../static/icons/school.svg";
import ship              from "../static/icons/ship.svg";
import store             from "../static/icons/store.svg";
import swimmer           from "../static/icons/swimmer.svg";
import trail_access      from "../static/icons/trail-access.svg";
import wineglassalt      from "../static/icons/wine-glass-alt.svg";
import trees             from "../static/icons/trees.svg";
import building          from "../static/icons/building.svg";
import question          from "../static/icons/question.svg";
import shuttle_van       from "../static/icons/shuttle-van.svg";
import surf              from "../static/icons/surf.svg";
import chair_rest        from "../static/icons/chair_rest.svg";
import binoculars        from "../static/icons/binoculars.svg";
import book_open         from "../static/icons/book-open.svg";
import building_columns  from "../static/icons/building-columns.svg";
import cross             from "../static/icons/cross.svg";
import info_circle       from "../static/icons/info-circle.svg";
import life_ring         from "../static/icons/life-ring.svg";
import mug               from "../static/icons/mug.svg";
import paint_brush       from "../static/icons/paint-brush.svg";
import popcorn           from "../static/icons/popcorn.svg";
import sailboat          from "../static/icons/sailboat.svg";
import walking           from "../static/icons/walking.svg";
import train             from "../static/icons/train.svg";
import mask              from "../static/icons/mask.svg";

const icons = {
  'marker-amber': marker_amber,
  'marker-blue': marker_blue,
  'marker-dkblue': marker_dkblue,
  'marker-brown': marker_brown,
  'marker-cyan': marker_cyan,
  'marker-deeppurple': marker_deeppurple,
  'marker-dkorange': marker_dkorange,
  'marker-green': marker_green,
  'marker-dkgreen': marker_dkgreen,
  'marker-grey': marker_grey,
  'marker-indigo': marker_indigo,
  'marker-lime': marker_lime,
  'marker-ltblue': marker_ltblue,
  'marker-ltgreen': marker_ltgreen,
  'marker-orange': marker_orange,
  'marker-pink': marker_pink,
  'marker-purple': marker_purple,
  'marker-red': marker_red,
  'marker-teal': marker_teal,
  'marker-yellow': marker_yellow,
  'shadow': shadow,
  'beer': beer,
  'bike': bike,
  'coffee': coffee,
  'home': home,
  'shopping-bag': shopping,
  'utensils': utensils,
  'anchor': anchor,
  'school': school,
  'campground': campground,
  'wine-glass-alt': wineglassalt,
  'apple-alt': applealt,
  'store': store,
  'swimmer': swimmer,
  'hotel': hotel,
  'restroom': restroom,
  'parking': parking,
  'trees': trees,
  'ship': ship,
  'trail_access': trail_access,
  'building': building,
  'question': question,
  'shuttle-van': shuttle_van,
  'surf': surf,
  'chair_rest': chair_rest,
  'binoculars': binoculars,
  'book_open': book_open,
  'building_columns': building_columns,
  'cross': cross,
  'info_circle': info_circle,
  'life_ring': life_ring,
  'mug': mug,
  'paint_brush': paint_brush,
  'popcorn': popcorn,
  'sailboat': sailboat,
  'walking': walking,
  'train': train,
  'mask': mask
}

export default icons;
